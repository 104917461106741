import { ThemeProvider } from "emotion-theming";
import React, { ReactNode } from "react";

import { theme } from "./styling/theme";

type ElementType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any;
};

export default ({ element }: ElementType): ReactNode => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);
