// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-homepage-tsx": () => import("./../src/pages/Homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-success-stories-tsx": () => import("./../src/pages/SuccessStories.tsx" /* webpackChunkName: "component---src-pages-success-stories-tsx" */),
  "component---src-pages-case-studies-moplay-tsx": () => import("./../src/pages/caseStudies/Moplay.tsx" /* webpackChunkName: "component---src-pages-case-studies-moplay-tsx" */),
  "component---src-pages-case-studies-fourfinance-tsx": () => import("./../src/pages/caseStudies/Fourfinance.tsx" /* webpackChunkName: "component---src-pages-case-studies-fourfinance-tsx" */),
  "component---src-pages-case-studies-cemex-tsx": () => import("./../src/pages/caseStudies/Cemex.tsx" /* webpackChunkName: "component---src-pages-case-studies-cemex-tsx" */),
  "component---src-pages-case-studies-metrostav-tsx": () => import("./../src/pages/caseStudies/Metrostav.tsx" /* webpackChunkName: "component---src-pages-case-studies-metrostav-tsx" */),
  "component---src-pages-case-studies-weeducate-tsx": () => import("./../src/pages/caseStudies/Weeducate.tsx" /* webpackChunkName: "component---src-pages-case-studies-weeducate-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-case-studies-meatbones-tsx": () => import("./../src/pages/caseStudies/Meatbones.tsx" /* webpackChunkName: "component---src-pages-case-studies-meatbones-tsx" */),
  "component---src-pages-case-studies-itsounds-tsx": () => import("./../src/pages/caseStudies/Itsounds.tsx" /* webpackChunkName: "component---src-pages-case-studies-itsounds-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

