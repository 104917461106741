export const colors = {
  black: "#000000",
  white: "#ffffff",
  gray: "#555555",
  darkText: "#151515",
  lightGray: "#999999",
  backgroundGray: "#09090a",
  primary: "#fff200",
  borderGray: "#e3e3e3"
};

export const MOBILE_VERSION_BREAKPOINT = 1080;
export const breakpoints = {
  zero: 0,
  xs: 480,
  s: 520,
  m: 720,
  md: 960,
  l: MOBILE_VERSION_BREAKPOINT,
  lg: 1280,
  xl: 1340,
  mac: 1440,
  xxl: 1920,
  infinity: Infinity
};

export const media = {
  maxXs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
  maxS: `@media screen and (max-width: ${breakpoints.s - 1}px)`,
  maxM: `@media screen and (max-width: ${breakpoints.m - 1}px)`,
  maxMD: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  maxL: `@media screen and (max-width: ${breakpoints.l - 1}px)`,
  maxLg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  maxMac: `@media screen and (max-width: ${breakpoints.mac - 1}px)`,
  maxXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  zero: `@media screen and (min-width: ${breakpoints.zero}px)`,
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  s: `@media screen and (min-width: ${breakpoints.s}px)`,
  m: `@media screen and (min-width: ${breakpoints.m}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  l: `@media screen and (min-width: ${breakpoints.l}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  mac: `@media screen and (min-width: ${breakpoints.mac}px)`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,
  retina: "@media (-webkit-min-device-pixel-ratio: 2)"
};

export const fontSizeBase = 16;
export const defaultTransitionTime = 0.3;

// helper functions
type emT = (val: number, base: number) => string;
export const em: emT = (val, base = fontSizeBase) => {
  return `${Math.round((val / base) * 10000) / 10000}em`;
};

type remT = (val: number) => string;
export const rem: remT = (val: number) => {
  return `${Math.round((val / fontSizeBase) * 10000) / 10000}rem`;
};

type pixelizeT = (value: number) => string;
export const pixelize: pixelizeT = value => {
  return `${value}px`;
};

export interface Colors {
  black: string;
  white: string;
  gray: string;
  darkText: string;
  lightGray: string;
  backgroundGray: string;
  primary: string;
  borderGray: string;
}

export interface Media {
  maxLg: string;
  maxXs: string;
  maxS: string;
  maxM: string;
  maxMD: string;
  maxL: string;
  maxMac: string;
  maxXl: string;
  zero: string;
  xs: string;
  s: string;
  m: string;
  md: string;
  l: string;
  lg: string;
  xl: string;
  mac: string;
  xxl: string;
  retina: string;
}

export interface Theme {
  colors: Colors;
  media: Media;
  em: (val: number, base: number) => string;
  rem: (val: number) => string;
}

export const theme: Theme = {
  colors,
  em,
  media,
  rem
};
